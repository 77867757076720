import TokenService from "./token";
import API from './api'

class AuthService {
    login({ email, password }) {
        return API
            .post("/admin/auth/signin", {
                email, password
            })
            .then(response => {
                if (response.data) {
                    TokenService.setAdmin(response.data);
                }
                return response;
            });
    }

    logout() {
       const {_id} = TokenService.getAdmin()
        return API
            .post("/admin/auth/logout",{adminId:_id})
            .then(response => {
                TokenService.removeAdmin();
                return response.data;
            }).then(error => console.log({ error }));
    }

    getCurrentAdmin() {
        return TokenService.getAdmin();
    }
}

export default new AuthService();

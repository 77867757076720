import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allLinks: {
    loading: false,
    subLinks: [],
    error: "",
    page: 1,
    pageSize: 1,
    pages: 1,
  },
  subLinkDetails: {
    loading: false,
    details: {},
    error: "",
  },
};

export const fetchAllSubLinks = createAsyncThunk(
  "sub_links",
  (page = 1, pageSize = 5) => {
    const url = `/sub-link/all-sub-links?page=${page}&pageSize=${pageSize}`;
    return API.post(url);
  }
);

export const fetchSubLinkDetails = createAsyncThunk(
  "sub_link_details",
  async (linkId) => {
    return await API.post(`/sub-link/details/${linkId}`);
  }
);

export const createSubLink = createAsyncThunk("create_subLink", (data) => {
  return API.post("/sub-link/create", data);
});

export const updateSubLinkStatus = createAsyncThunk(
  "delete_subLink",
  (linkId) => {
    return API.patch(`/sub-link/update-status/${linkId}`);
  }
);

const allSubLinkSlice = createSlice({
  name: "subLinks",
  initialState: initialState.allLinks,
  extraReducers: (builder) => {
    builder.addCase(fetchAllSubLinks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllSubLinks.fulfilled, (state, action) => {
      state.loading = false;
      state.subLinks = action.payload.data.allLinks;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
    });
    builder.addCase(fetchAllSubLinks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // create new link
    builder.addCase(createSubLink.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSubLink.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
      // state.subLinks = action.payload;
    });
    builder.addCase(createSubLink.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // update link
    builder.addCase(updateSubLinkStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSubLinkStatus.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
      // state.subLinks = action.payload;
      state.subLinks = state.subLinks.map((item) => {
        if (item._id == action.payload.data.subLink._id) {
          item.isActive = action.payload.data.subLink.isActive;
          return item;
        }
        return item;
      });
    });
    builder.addCase(updateSubLinkStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const subLinkDetailsSlice = createSlice({
  name: "subLinkDetails",
  initialState: initialState.subLinkDetails,
  extraReducers: (builder) => {
    builder.addCase(fetchSubLinkDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubLinkDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.details = action.payload;
    });
    builder.addCase(fetchSubLinkDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const subLinkDetailsReducer = subLinkDetailsSlice.reducer;
export const allSubLinkReducer = allSubLinkSlice.reducer;

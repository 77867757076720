import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allUser: {
    loading: false,
    allUsers: [],
    error: "",
    page: 1,
    pageSize: 1,
    pages: 1,
  },
  userDetails: {
    loading: false,
    user: {},
    error: "",
    channel: null,
    account: null,
  },
};

export const fetchAllUsers = createAsyncThunk(
  "all_users",
  (page = 1, pageSize = 5) => {
    const url = `/influencer/all-influencer?page=${page}&pageSize=${pageSize}`;
    return API.post(url);
  }
);

export const fetchUserDetails = createAsyncThunk("user_details", (userId) =>
  API.post(`/influencer/details/${userId}`)
);

export const blockUser = createAsyncThunk("block_user", (userId) =>
  API.patch(`/influencer/update-status/${userId}`)
);

const allUserSlice = createSlice({
  name: "AllUsers",
  initialState: initialState.allUser,
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.allUsers = action.payload.data.users;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
    });
    builder.addCase(fetchAllUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    // change user active status
    builder.addCase(blockUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(blockUser.fulfilled, (state, action) => {
      state.loading = false;
      state.allUsers = state.allUsers.map((item) => {
        if (item._id == action.payload.data.influencer._id) {
          item.isActive = action.payload.data.influencer.isActive;
          return item;
        }
        return item;
      });
    });
    builder.addCase(blockUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const userDetailSlice = createSlice({
  name: "userDetails",
  initialState: initialState.userDetails,
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload.data.influencer;
      state.channel = action.payload.data.channels;
      state.account = action.payload.data.account;
    });
    builder.addCase(fetchUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const allUserReducer = allUserSlice.reducer;
export const userDetailsReducer = userDetailSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  admin: {
    success: false,
    error: "",
    admin: {},
    loading: false,
    isAuthenticated: false,
  },
  allAdmins: {
    error: "",
    allAdmins: [],
    loading: false,
    page: 1,
    pageSize: 1,
    pages: 1,
  },
};

export const fetchAdmins = createAsyncThunk(
  "all_admins",
  (page = 1, pageSize = 5) => {
    const url = `/admin/all-admins?page=${page}&pageSize=${pageSize}`;
    return API.get(url);
  }
);
export const createAdmin = createAsyncThunk("create_admin", (payload) => {
  return API.post("/admin/create", payload);
});
export const loginAdmin = createAsyncThunk("login", (payload) => {
  return API.post("/admin/login", payload);
});

export const updateAdminRole = createAsyncThunk(
  "update_role",
  ({ adminId, role }) => {
    return API.patch(`/admin/update-role/${adminId}`, { role });
  }
);

export const updateAdminStatus = createAsyncThunk("delete_admin", (id) => {
  return API.patch(`/admin/update-delete/${id}`);
});
export const changePassword = createAsyncThunk(
  "update_password",
  ({ adminId, ...data }) => {
    return API.patch(`/admin/update-password/${adminId}`, data);
  }
);

export const permanentDeleteAdmin = createAsyncThunk(
  "permanent_delete",
  (adminId) => {
    return API.delete(`/admin/permanent-delete/${adminId}`);
  }
);
export const getAdmin = createAsyncThunk("get_admin", () => {
  let admin = localStorage.getItem("admin");
  if (admin) {
    admin = JSON.parse(admin);
    return admin;
  } else {
    return null;
  }
});
const allAdminSlice = createSlice({
  name: "allAdmins",
  initialState: initialState.allAdmins,
  extraReducers: (builder) => {
    builder.addCase(fetchAdmins.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdmins.fulfilled, (state, action) => {
      state.loading = false;
      state.allAdmins = action.payload.data.allAdmins;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
    });
    builder.addCase(fetchAdmins.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    // create new admin
    builder.addCase(createAdmin.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(createAdmin.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
    });
    builder.addCase(createAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // update role
    builder.addCase(updateAdminRole.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminRole.fulfilled, (state, action) => {
      state.loading = false;
      state.allAdmins = state.allAdmins.map((item) => {
        if (item._id == action.payload.data.admin._id) {
          item.role = action.payload.data.admin.role;
          return item;
        }
        return item;
      });
    });
    builder.addCase(updateAdminRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // block unblock admin
    builder.addCase(updateAdminStatus.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.allAdmins = state.allAdmins.map((item) => {
        if (item._id == action.payload.data.admin._id) {
          item.isActive = action.payload.data.admin.isActive;
          return item;
        }
        return item;
      });
    });
    builder.addCase(updateAdminStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // permanent delete admin
    builder.addCase(permanentDeleteAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(permanentDeleteAdmin.fulfilled, (state, action) => {
      state.loading = false;
      state.allAdmins = state.allAdmins.filter(
        (item) => item._id != action.payload.data.adminId
      );
    });
    builder.addCase(permanentDeleteAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});
const adminSlice = createSlice({
  name: "admin",
  initialState: initialState.admin,
  extraReducers: (builder) => {
    builder.addCase(loginAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      state.loading = false;
      localStorage.setItem(
        "token",
        JSON.stringify(action.payload.data.admin.token)
      );
      state.admin = {
        name: action.payload.data.admin.name,
        role: action.payload.data.admin.role,
        _id: action.payload.data.admin._id,
        image: action.payload.data.admin?.image,
      };
      state.success = true;
      localStorage.setItem("admin", JSON.stringify(state.admin));
    });
    builder.addCase(loginAdmin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // get admin details
    builder.addCase(getAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAdmin.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload);
    });
    builder.addCase(getAdmin.rejected, (state, action) => {
      state.loading = false;
      state.admin = null;
    });
  },
});

export const adminReducer = adminSlice.reducer;
export const allAdminReducer = allAdminSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  loading: false,
  activities: [],
  error: "",
};

export const fetchActivities = createAsyncThunk("activities", () => {
  return API.get("/activities");
});

const activitySlice = createSlice({
  name: "activity",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchActivities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchActivities.fulfilled, (state, action) => {
      state.loading = false;
      state.activities = action.payload;
      state.error = "";
    });
    builder.addCase(fetchActivities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.activities = [];
    });
  },
});

export const activityReducer = activitySlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allChannels: {
    loading: false,
    channels: [],
    error: "",
    page: 1,
    pageSize: 1,
    pages: 1,
  },
  channelDetails: {
    loading: false,
    channel: {},
    error: "",
  },
};

export const fetchAllChannels = createAsyncThunk(
  "All_channels",
  (page = 1, pageSize = 5) => {
    const url = `/channel/all-channels?page=${page}&pageSize=${pageSize}`;
    return API.get(url);
  }
);
export const fetchChannelDetails = createAsyncThunk(
  "channelDetails",
  (channelId) => API.post(`/channel/details/${channelId}`)
);
export const updateChannelStatus = createAsyncThunk(
  "delete_channel",
  (channelId) => {
    return API.patch(`/channel/update-status/${channelId}`);
  }
);
export const updateMediaStatus = createAsyncThunk(
  "update_media_state",
  async ({ channelId, mediaId }) => {
    return await API.patch(`/channel/${channelId}/update/${mediaId}`);
  }
);

const allChannelSlice = createSlice({
  name: "allChannels",
  initialState: initialState.allChannels,
  extraReducers: (builder) => {
    builder.addCase(fetchAllChannels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllChannels.fulfilled, (state, action) => {
      state.loading = false;
      state.channels = action.payload.data.allChannels;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
    });
    builder.addCase(fetchAllChannels.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // update media status
    builder.addCase(updateMediaStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMediaStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.channels = state.channels.filter(
        (item) => item._id != action.payload.data.channel._id
      );
      state.channels.unshift(action.payload.data.channel);
    });
    builder.addCase(updateMediaStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    // update channel status
    builder.addCase(updateChannelStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateChannelStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.channels = state.channels.map((item) => {
        if (item._id == action.payload.data.channel._id) {
          item.isActive = action.payload.data.channel.isActive;
          return item;
        }
        return item;
      });
    });
    builder.addCase(updateChannelStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const channelDetailSlice = createSlice({
  name: "channelDetails",
  initialState: initialState.channelDetails,
  extraReducers: (builder) => {
    builder.addCase(fetchChannelDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchChannelDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.channel = action.payload;
    });
    builder.addCase(fetchChannelDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const allChannelReducer = allChannelSlice.reducer;
export const channelDetailsReducer = channelDetailSlice.reducer;

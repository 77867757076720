class TokenService {
    getLocalRefreshToken() {
        const admin = JSON.parse(localStorage.getItem("adminInfo"));
        return admin?.refreshToken;
    }

    getLocalAccessToken() {
        const admin = JSON.parse(localStorage.getItem("adminInfo"));
        return admin?.accessToken;
    }

    updateLocalRfreshToken(token) {
        let admin = JSON.parse(localStorage.getItem("adminInfo"));
        admin.refreshToken = token;
        localStorage.setItem("adminInfo", JSON.stringify(admin));
    }
    updateLocalAccessToken(token) {
        let admin = JSON.parse(localStorage.getItem("adminInfo"));
        admin.accessToken = token;
        localStorage.setItem("adminInfo", JSON.stringify(admin));
    }

    getAdmin() {
        return JSON.parse(localStorage.getItem("adminInfo"));
    }
    getAdminDetails() {
        let admin = JSON.parse(localStorage.getItem("adminInfo"));
        return admin?.admin
    }
    setAdmin(admin) {
        localStorage.setItem("adminInfo", JSON.stringify(admin));
    }

    removeAdmin() {
        localStorage.removeItem("adminInfo");
    }
}

export default new TokenService();
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allCategories: {
    loading: false,
    categories: [],
    error: "",
    success: false,
    page: 1,
    pages: 1,
    pageSize: 1,
  },
  allCommonCategories: {
    loading: false,
    categories: [],
    error: "",
    success: false,
  },
  categoryDetails: {
    loading: false,
    details: {},
    error: "",
    success: false,
  },
};

export const fetchAllCategories = createAsyncThunk(
  "all_categories",
  (page = 1, pageSize = 5) => {
    const url = `/categories/all-categories?page=${page}&pageSize=${pageSize}`;
    return API.post(url);
  }
);
export const fetchAllCommonCategories = createAsyncThunk(
  "all_common_categories",
  () => API.get("/categories/all-categories")
);

export const fetchCategoryDetails = createAsyncThunk(
  "category_details",
  (categoryId) => API.post(`/categories/details/${categoryId}`)
);

export const createCategory = createAsyncThunk("create_category", (body) =>
  API.post("/categories/create", body)
);

export const changeCategoryStatus = createAsyncThunk(
  "update_category_status",
  (categoryId) => API.patch(`/categories/change-status/${categoryId}`)
);

export const updateCategory = createAsyncThunk(
  "update_category",
  ({ categoryId, name }) =>
    API.patch(`/categories/update/${categoryId}`, { name })
);

const allCategorySlice = createSlice({
  name: "allCategories",
  initialState: initialState.allCategories,
  extraReducers: (builder) => {
    builder.addCase(fetchAllCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
      state.categories = action.payload.data.allCategories;
    });
    builder.addCase(fetchAllCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // create new category
    builder.addCase(createCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.categories.unshift(action.payload.data.category);
    });
    builder.addCase(createCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.success = false;
    });
    // update status
    builder.addCase(changeCategoryStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeCategoryStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = state.categories.map((item) => {
        if (item._id == action.payload.data.category._id) {
          item.isActive = action.payload.data.category.isActive;
          return item;
        }
        return item;
      });
    });
    builder.addCase(changeCategoryStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.success = false;
    });
    // update name
    builder.addCase(updateCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = state.categories.map((item) => {
        if (item._id == action.payload.data.category._id) {
          item.name = action.payload.data.category.name;
          return item;
        }
        return item;
      });
    });
    builder.addCase(updateCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.success = false;
    });
  },
});

const allCommonCategorySlice = createSlice({
  name: "allCommonCategories",
  initialState: initialState.allCommonCategories,
  extraReducers: (builder) => {
    builder.addCase(fetchAllCommonCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllCommonCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload.data.categories;
    });
    builder.addCase(fetchAllCommonCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const categoryDetailsSlice = createSlice({
  name: "categoryDetails",
  initialState: initialState.categoryDetails,
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCategoryDetails.fulfilled, (state, action) => {
      state.loading = true;
      state.details = action.payload;
    });
    builder.addCase(fetchCategoryDetails.rejected, (state, action) => {
      state.loading = true;
      state.error = action.error;
    });
  },
});

export const allCategoriesReducer = allCategorySlice.reducer;
export const allCommonCategoriesReducer = allCommonCategorySlice.reducer;
export const categoryDetailsReducer = categoryDetailsSlice.reducer;

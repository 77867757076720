import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allAffiliations: {
    success: false,
    loading: false,
    error: "",
    allAffiliations: [],
    page: 1,
    pages: 1,
    pageSize: 1,
  },
  commonAffiliation: {
    success: false,
    loading: false,
    error: "",
    allAffiliations: [],
  },
  affiliationDetails: {
    success: false,
    loading: false,
    error: "",
    affiliation: {},
  },
};

export const fetchAllAffiliation = createAsyncThunk(
  "all_affiliations",
  (page = 1, pageSize = 5) => {
    const url = `/affiliation/all-affiliations?page=${page}&pageSize=${pageSize}`;
    return API.post(url);
  }
);

export const fetchCommonAffiliations = createAsyncThunk(
  "common_affiliations",
  () => API.get("/affiliation/all-affiliations")
);

export const fetchAffiliationsDetails = createAsyncThunk(
  "affiliation_details",
  (affiliationId) => API.get(`/affiliation/details/${affiliationId}`)
);

export const createAffiliation = createAsyncThunk(
  "create_affiliation",
  (body) => API.post(`/affiliation/create`, body)
);

export const deleteAffiliation = createAsyncThunk(
  "delete_affiliation",
  (partnerId) => API.patch(`/affiliation/change-status/${partnerId}`)
);

const allCommonAffiliationsSlice = createSlice({
  name: "commonAffiliations",
  initialState: initialState.commonAffiliation,
  extraReducers: (builder) => {
    builder.addCase(fetchCommonAffiliations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCommonAffiliations.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.allAffiliations = action.payload.data.affiliations;
      state.error = "";
    });
    builder.addCase(fetchCommonAffiliations.rejected, (state, action) => {
      state.loading = false;
      state.allAffiliations = [];
      state.error = action.error;
    });
  },
});

export const allAffiliationsSlice = createSlice({
  name: "allAffiliation",
  initialState: initialState.allAffiliations,
  extraReducers: (builder) => {
    builder.addCase(fetchAllAffiliation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllAffiliation.fulfilled, (state, action) => {
      state.loading = false;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
      state.allAffiliations = action.payload.data.affiliations;
      state.error = "";
    });
    builder.addCase(fetchAllAffiliation.rejected, (state, action) => {
      state.loading = false;
      state.allAffiliations = [];
      state.error = action.error;
    });
    builder.addCase(createAffiliation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAffiliation.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.allAffiliations.unshift(action?.payload?.data?.affiliation);
      state.error = "";
    });
    builder.addCase(createAffiliation.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error;
    });

    builder.addCase(deleteAffiliation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAffiliation.fulfilled, (state, action) => {
      state.loading = false;
      state.allAffiliations = state.allAffiliations.map((item) => {
        if (item._id == action.payload.data.affiliation._id) {
          item.isActive = action.payload.data.affiliation.isActive;
          return item;
        }
        return item;
      });
      state.success = true;
    });
    builder.addCase(deleteAffiliation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const affiliationDetailsSlice = createSlice({
  name: "affiliationDetails",
  initialState: initialState.affiliationDetails,
  extraReducers: (builder) => {
    builder.addCase(fetchAffiliationsDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAffiliationsDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.affiliation = action.payload;
    });
    builder.addCase(fetchAffiliationsDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.affiliation = {};
    });
  },
});

export const affiliationDetailsReducer = affiliationDetailsSlice.reducer;
export const allAffiliationReducer = allAffiliationsSlice.reducer;
export const allCommonAffiliationsReducer = allCommonAffiliationsSlice.reducer;

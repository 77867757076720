import axios from "axios";
import { BACKEND_URL, BACKEND_CLOUD_URL } from "src/constants/url";
import TokenService from "./token";
import AuthService from "../api/auth";

const instance = axios.create({
  baseURL: BACKEND_CLOUD_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    // console.log({error});
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/admin/login" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const { data } = await instance.post("/admin/auth/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });
          if (data.token_hack) {
            AuthService.logout();
            return;
          }
          const { accessToken, refreshToken } = data;
          TokenService.updateLocalAccessToken(accessToken);
          TokenService.updateLocalRfreshToken(refreshToken);

          return instance(originalConfig);
        } catch (_error) {
          if (_error?.response?.data?.error === "Invalid refresh token") {
            TokenService.removeAdmin();
          }
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;

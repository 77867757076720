import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allStores: {
    loading: false,
    error: "",
    allStores: [],
    page: 1,
    pageSize: 1,
    pages: 1,
  },
  trendingStores: {
    loading: false,
    error: "",
    allStores: [],
  },
  commonStores: {
    loading: false,
    error: "",
    allStores: [],
  },
  storeDetails: {
    loading: false,
    error: "",
    store: {},
    success: false,
  },
};

export const fetchAllStores = createAsyncThunk(
  "all_stores",
  (page = 1, pageSize = 5) => {
    const url = `/stores/all-stores?page=${page}&pageSize=${pageSize}`;
    return API.post(url);
  }
);
export const fetchCommonStores = createAsyncThunk("common_stores", () => {
  return API.get("/stores/all-stores");
});
export const fetchStoreDetails = createAsyncThunk(
  "stores_details",
  (storeId) => {
    return API.get(`/stores/details/${storeId}`);
  }
);

export const createStore = createAsyncThunk("create_store", (body) => {
  return API.post("/stores/create", body);
});

export const changeStoreStatus = createAsyncThunk("delete_store", (storeId) => {
  return API.patch(`/stores/update-status/${storeId}`);
});

const allCommonStoresSlice = createSlice({
  name: "commonStores",
  initialState: initialState.commonStores,
  extraReducers: (builder) => {
    builder.addCase(fetchCommonStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCommonStores.fulfilled, (state, action) => {
      state.loading = false;
      state.allStores = action.payload.data.stores;
    });
    builder.addCase(fetchCommonStores.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const allStoresSlice = createSlice({
  name: "allStores",
  initialState: initialState.allStores,
  extraReducers: (builder) => {
    builder.addCase(fetchAllStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStores.fulfilled, (state, action) => {
      state.loading = false;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
      state.allStores = action.payload.data.stores;
    });
    builder.addCase(fetchAllStores.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // create store
    builder.addCase(createStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createStore.fulfilled, (state, action) => {
      state.loading = false;
      state.allStores.push(action.error.data.store);
    });
    builder.addCase(createStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // change delete status of store
    builder.addCase(changeStoreStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeStoreStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.allStores = state.allStores.map((item) => {
        if (item._id === action.payload.data.store._id) {
          item.isActive = action.payload.data.store.isActive;
          return item;
        }
        return item;
      });
    });
    builder.addCase(changeStoreStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const storeDetailsSlice = createSlice({
  name: "storeDetails",
  initialState: initialState.storeDetails,
  extraReducers: (builder) => {
    builder.addCase(fetchStoreDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStoreDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.store = action.payload;
    });
    builder.addCase(fetchStoreDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const fetchAllTrendingStores = createAsyncThunk(
  "all_trending_stores",
  ({ page = 1, pageSize = 5 }) => {
    return API.post(`/stores/trending/all?page=${page}&pageSize=${pageSize}`);
  }
);

export const createTrendingStore = createAsyncThunk(
  "create_trending_store",
  (payload) => {
    return API.post(`/stores/trending/create`, payload);
  }
);

export const deleteTrendingStore = createAsyncThunk(
  "delete_trending_store",
  (storeId) => {
    return API.delete(`/stores/trending/delete/${storeId}`);
  }
);

const trendingStoreSlice = createSlice({
  name: "trendingStores",
  initialState: initialState.trendingStores,
  extraReducers: (builder) => {
    builder.addCase(fetchAllTrendingStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllTrendingStores.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
    });
    builder.addCase(fetchAllTrendingStores.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // create trending store
    builder.addCase(createTrendingStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTrendingStore.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
    });
    builder.addCase(createTrendingStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // delete trending store
    builder.addCase(deleteTrendingStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTrendingStore.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
    });
    builder.addCase(deleteTrendingStore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const storeDetailsReducer = storeDetailsSlice.reducer;
export const allStoresReducer = allStoresSlice.reducer;
export const allCommonStoreReducer = allCommonStoresSlice.reducer;
export const trendingStoreReducer = trendingStoreSlice.reducer;

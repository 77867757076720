import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allAccounts: {
    loading: false,
    allAccounts: [],
    error: "",
    pageSize: 1,
    pages: 1,
    page: 1,
  },
  accountDetails: {
    loading: false,
    details: {},
    error: "",
  },
};

export const fetchAllAccounts = createAsyncThunk(
  "all_accounts",
  (page = 1, pageSize = 5) => {
    const url = `/account-details/all-accounts?page=${page}&pageSize=${pageSize}`;
    return API.post(url);
  }
);

export const fetchAccountDetails = createAsyncThunk(
  "account_details",
  (accountId) => API.post(`/account-details/details/${accountId}`)
);

export const blockAccount = createAsyncThunk("block_account", (accountId) =>
  API.patch(`/account-details/block/${accountId}`)
);

const allAccountSlice = createSlice({
  name: "allAccounts",
  initialState: initialState.allAccounts,
  extraReducers: (builder) => {
    builder.addCase(fetchAllAccounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllAccounts.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
      state.allAccounts = action.payload.data.accounts;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
    });
    builder.addCase(fetchAllAccounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(blockAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(blockAccount.fulfilled, (state, action) => {
      state.loading = false;
      state.allAccounts = state.allAccounts.map((item) => {
        if (item._id == action.payload.data.account._id) {
          item.isActive = action.payload.data.account.isActive;
          return item;
        }
        return item;
      });
    });
    builder.addCase(blockAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const accountDetailSlice = createSlice({
  name: "accountDetails",
  initialState: initialState.accountDetails,
  extraReducers: (builder) => {
    builder.addCase(fetchAccountDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAccountDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.details = action.payload;
    });
    builder.addCase(fetchAccountDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const allAccountReducer = allAccountSlice.reducer;
export const accountDetailsReducer = accountDetailSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allSubCategories: {
    loading: false,
    categories: [],
    error: "",
    page: 1,
    pages: 1,
    pageSize: 1,
  },
  allCommonSubCategories: {
    loading: false,
    categories: [],
    error: "",
  },
  subCategoryDetails: {
    loading: false,
    details: {},
    error: "",
  },
};

export const fetchAllSubCategories = createAsyncThunk(
  "all_sub_categories",
  (page = 1, pageSize = 5) => {
    const url = `/categories/sub/all-categories?page=${page}&pageSize=${pageSize}`;
    return API.post(url);
  }
);
export const fetchAllCommonSubCategories = createAsyncThunk(
  "all_common_sub_categories",
  () => API.get("/categories/sub/all-categories")
);

export const fetchSubCategoryDetails = createAsyncThunk(
  "sub_category_details",
  (categoryId) => API.post(`/categories/sub/details/${categoryId}`)
);

export const createSubCategory = createAsyncThunk(
  "create_subcategory",
  (data) => API.post("/categories/sub/create", data)
);

export const changeCategoryStatus = createAsyncThunk(
  "change_status",
  (categoryId) => API.patch(`/categories/sub/change-status/${categoryId}`)
);

export const updateSubCategory = createAsyncThunk(
  "update_sub_category",
  async (categoryId, data) => {
    return await API.put(`/categories/sub/update/${categoryId}`, data);
  }
);

const allSubCategorySlice = createSlice({
  name: "allSubCategories",
  initialState: initialState.allSubCategories,
  extraReducers: (builder) => {
    builder.addCase(fetchAllSubCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllSubCategories.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
      state.categories = action.payload.data.allCategories;
    });
    builder.addCase(fetchAllSubCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // create category
    builder.addCase(createSubCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSubCategory.fulfilled, (state, action) => {
      state.loading = false;

      // console.log(action.payload.data);
    });
    builder.addCase(createSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    // delete category
    builder.addCase(changeCategoryStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeCategoryStatus.fulfilled, (state, action) => {
      state.loading = false;
      const data = state.categories.map((item) => {
        if (item._id == action.payload.data.category._id) {
          item.isActive = action.payload.data.category.isActive;
          return item;
        }
        return item;
      });
      state.categories = data;
    });
    builder.addCase(changeCategoryStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // update sub category
    builder.addCase(updateSubCategory.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateSubCategory.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
    });
    builder.addCase(updateSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const allCommonSubCategorySlice = createSlice({
  name: "allCommonSubCategories",
  initialState: initialState.allCommonSubCategories,
  extraReducers: (builder) => {
    builder.addCase(fetchAllCommonSubCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllCommonSubCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload.data.categories;
    });
    builder.addCase(fetchAllCommonSubCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const subCategoryDetailsSlice = createSlice({
  name: "subCategoryDetails",
  initialState: initialState.subCategoryDetails,
  extraReducers: (builder) => {
    builder.addCase(fetchSubCategoryDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSubCategoryDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.details = action.payload.data.category;
    });
    builder.addCase(fetchSubCategoryDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const allSubCategoriesReducer = allSubCategorySlice.reducer;
export const allCommonSubCategoriesReducer = allCommonSubCategorySlice.reducer;
export const subCategoryDetailsReducer = subCategoryDetailsSlice.reducer;

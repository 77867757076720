import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebar: true,
};

const sidebarSlice = createSlice({
  initialState,
  name: "sidebar",
  reducers: {
    handleSideBar: (state, action) => {
      state.sidebar = action.payload;
    },
  },
});

export const { handleSideBar } = sidebarSlice.actions;

export default sidebarSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allMiddlewares: {
    loading: false,
    allMiddlewares: [],
    error: "",
    page: 1,
    pageSize: 1,
    pages: 1,
  },

  middlewareDetails: {
    loading: false,
    details: {},
    error: "",
  },
};

export const fetchAllMiddlewares = createAsyncThunk(
  "all_middlewares",
  (page = 1, pageSize = 5) => {
    const url = `/access-controls/all-access?page=${page}&pageSize=${pageSize}`;
    return API.post(url);
  }
);

export const createMiddleware = createAsyncThunk(
  "create_middleware",
  (payload) => {
    return API.post("/access-controls/create", payload);
  }
);

export const addAccessToMiddleware = createAsyncThunk(
  "add_access",
  ({ middlewareId, access }) => {
    return API.patch(`/access-controls/add-access/${middlewareId}`, { access });
  }
);

export const removeAccessToMiddleware = createAsyncThunk(
  "remove_access",
  ({ middlewareId, access }) => {
    return API.patch(`/access-controls/remove-access/${middlewareId}`, {
      access,
    });
  }
);

export const updateMiddleware = createAsyncThunk(
  "update_access",
  ({ middlewareId, name }) => {
    return API.patch(`/access-controls/update-name/${middlewareId}`, {
      name,
    });
  }
);

const allMiddlewareSlice = createSlice({
  name: "allMiddlewares",
  initialState: initialState.allMiddlewares,
  extraReducers: (builder) => {
    builder.addCase(fetchAllMiddlewares.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMiddlewares.fulfilled, (state, action) => {
      state.loading = false;
      state.allMiddlewares = action.payload.data.allAccess;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
    });
    builder.addCase(fetchAllMiddlewares.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // create new middleware
    builder.addCase(createMiddleware.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMiddleware.fulfilled, (state, action) => {
      state.loading = false;
      console.log(action.payload.data);
    });
    builder.addCase(createMiddleware.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // add access
    builder.addCase(addAccessToMiddleware.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addAccessToMiddleware.fulfilled, (state, action) => {
      state.loading = false;
      state.allMiddlewares = state.allMiddlewares.map((item) => {
        if (item._id == action.payload.data.access._id) {
          item.features.push(action.payload.data.access.newAccess);
          return item;
        }
        return item;
      });
    });
    builder.addCase(addAccessToMiddleware.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // remove access
    builder.addCase(removeAccessToMiddleware.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(removeAccessToMiddleware.fulfilled, (state, action) => {
      state.loading = false;
      const accessString = action.payload.data.access.newAccess;
      state.allMiddlewares = state.allMiddlewares.map((item) => {
        if (item._id == action.payload.data.access._id) {
          item.features.filter((access) => access != accessString);
          return item;
        }
        return item;
      });
    });
    builder.addCase(removeAccessToMiddleware.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // update name
    builder.addCase(updateMiddleware.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMiddleware.fulfilled, (state, action) => {
      state.loading = false;
      state.allMiddlewares = state.allMiddlewares.map((item) => {
        if (item._id == action.payload.data.access._id) {
          item.name = action.payload.data.access.name;
          return item;
        }
        return item;
      });
    });
    builder.addCase(updateMiddleware.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const middlewareReducer = allMiddlewareSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allLinks: {
    loading: false,
    mainLinks: [],
    error: "",
    page: 1,
    pageSize: 1,
    pages: 1,
  },
  mainLinkDetails: {
    loading: false,
    details: {},
    error: "",
  },
};

export const fetchAllMainLinks = createAsyncThunk(
  "main_links",
  async (page = 1, pageSize = 5) => {
    const url = `/main-link/all-main-links?page=${page}&pageSize=${pageSize}`;
    return API.post(url);
  }
);

export const fetchMainLinkDetails = createAsyncThunk(
  "main_link_details",
  async (linkId) => {
    return await API.post(`/main-link/details/${linkId}`);
  }
);

export const updateLinkStatus = createAsyncThunk("delete_link", (linkId) => {
  return API.patch(`/main-link/update-status/${linkId}`);
});

const allMainLinkSlice = createSlice({
  name: "mainLinks",
  initialState: initialState.allLinks,
  extraReducers: (builder) => {
    builder.addCase(fetchAllMainLinks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMainLinks.fulfilled, (state, action) => {
      state.loading = false;
      state.mainLinks = action.payload.data.allLinks;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
    });
    builder.addCase(fetchAllMainLinks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    // update link status
    builder.addCase(updateLinkStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateLinkStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.mainLinks = state.mainLinks.map((item) => {
        if (item._id == action.payload.data.link._id) {
          item.isActive = action.payload.data.link.isActive;
          return item;
        }
        return item;
      });
    });
    builder.addCase(updateLinkStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const mainLinkDetailsSlice = createSlice({
  name: "mainLinkDetails",
  initialState: initialState.allLinks,
  extraReducers: (builder) => {
    builder.addCase(fetchMainLinkDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMainLinkDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.mainLinks = action.payload;
      state.error = "";
    });
    builder.addCase(fetchMainLinkDetails.rejected, (state, action) => {
      state.loading = false;
      state.mainLinks = [];
      state.error = action.error;
    });
  },
});

export const mainLinkDetailsReducer = mainLinkDetailsSlice.reducer;
export const allMainLinkReducer = allMainLinkSlice.reducer;

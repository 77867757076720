import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allOffers: {
    loading: false,
    error: "",
    allOffers: [],
    page: 1,
    pageSize: 1,
    pages: 1,
  },

  offerDetails: {
    loading: false,
    error: "",
    offer: {},
  },
};

export const fetchAllOffers = createAsyncThunk("all_offers", () => {
  return API.post("/offers/all-offers");
});

export const fetchOffersDetails = createAsyncThunk(
  "offers_details",
  (offerId) => {
    return API.get(`/offers/details/${offerId}`);
  }
);

export const createNewOffer = createAsyncThunk("create_offer", (data) => {
  return API.post("/offers/create", data);
});

export const changeOfferStatus = createAsyncThunk("delete_offer", (offerId) => {
  return API.patch(`/offers/update-status/${offerId}`);
});

const allOffersSlice = createSlice({
  name: "allOffers",
  initialState: initialState.allOffers,
  extraReducers: (builder) => {
    builder.addCase(fetchAllOffers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllOffers.fulfilled, (state, action) => {
      state.loading = false;
      state.allOffers = action.payload.data.allOffers;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
    });
    builder.addCase(fetchAllOffers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    // create new offer
    builder.addCase(createNewOffer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewOffer.fulfilled, (state, action) => {
      state.loading = false;
      state.allOffers.push(action.payload.data.offer);
    });
    builder.addCase(createNewOffer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    // update offer status
    builder.addCase(changeOfferStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeOfferStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.allOffers = state.allOffers.map((item) => {
        if (item._id == action.payload.data.offer._id) {
          item.isActive = action.payload.data.offer.isActive;
          return item;
        }
        return item;
      });
      // state.allOffers = action.payload;
    });
    builder.addCase(changeOfferStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

const offerDetailsSlice = createSlice({
  name: "offerDetails",
  initialState: initialState.offerDetails,
  extraReducers: (builder) => {
    builder.addCase(fetchOffersDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOffersDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.offer = action.payload;
    });
    builder.addCase(fetchOffersDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.offer = {};
    });
  },
});

export const offerDetailsReducer = offerDetailsSlice.reducer;
export const allOfferReducer = allOffersSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api/api";

const initialState = {
  allBonus: {
    loading: false,
    allBonus: [],
    error: "",
    page: 1,
    pageSize: 1,
    pages: 1,
  },
  bonusDetails: {
    loading: false,
    details: {},
    error: "",
  },
};

export const fetchAllBonus = createAsyncThunk(
  "allBonus",
  (page = 1, pageSize = 5) => {
    const url = `/bonus/all-bonus?page=${page}&pageSize=${pageSize}`;
    return API.get(url);
  }
);

export const updateBonusStatus = createAsyncThunk(
  "updateStatus",
  ({ id, status }) => {
    return API.patch(`/bonus/update-status/${id}`, { status });
  }
);
export const fetchBonusDetails = createAsyncThunk("bonusDetails", (id) =>
  API.post(`/bonus/${id}`)
);

const allBonusSlice = createSlice({
  name: "allBonus",
  initialState: initialState.allBonus,
  extraReducers: (builder) => {
    builder.addCase(fetchAllBonus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchAllBonus.fulfilled, (state, action) => {
      state.loading = false;
      state.allBonus = action.payload.data.allBonus;
      state.page = action.payload.data.page;
      state.pages = action.payload.data.pages;
      state.pageSize = action.payload.data.pageSize;
    });
    builder.addCase(fetchAllBonus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });

    builder.addCase(updateBonusStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateBonusStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.allBonus = state.allBonus.map((item) => {
        if (item._id == action.payload.data.bonus._id) {
          item.status = action.payload.data.bonus.status;
          return item;
        }
        return item;
      });
    });
    builder.addCase(updateBonusStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const allBonusReducer = allBonusSlice.reducer;

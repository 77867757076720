import { configureStore } from "@reduxjs/toolkit";
import { adminReducer, allAdminReducer } from "./features/adminSlice";
import sidebarReducer from "./features/sidebarSlice";
import {
  allCommonStoreReducer,
  allStoresReducer,
  storeDetailsReducer,
  trendingStoreReducer,
} from "./features/storeSlice";
import { allOfferReducer, offerDetailsReducer } from "./features/offerSlice";
import { activityReducer } from "./features/activitySlice";
import {
  affiliationDetailsReducer,
  allAffiliationReducer,
  allCommonAffiliationsReducer,
} from "./features/affiliationSlice";
import {
  allMainLinkReducer,
  mainLinkDetailsReducer,
} from "./features/mainLinkSlice";
import { allUserReducer, userDetailsReducer } from "./features/userSlice";
import {
  allChannelReducer,
  channelDetailsReducer,
} from "./features/channelSlice";
import {
  accountDetailsReducer,
  allAccountReducer,
} from "./features/accountDetailsSlice";
import { allBonusReducer } from "./features/bonusSlice";
import {
  allCategoriesReducer,
  allCommonCategoriesReducer,
} from "./features/categorySlice";
import {
  allCommonSubCategoriesReducer,
  allSubCategoriesReducer,
  subCategoryDetailsReducer,
} from "./features/subCategorySlice";
import {
  allSubLinkReducer,
  subLinkDetailsReducer,
} from "./features/subLinkSlice";
import { middlewareReducer } from "./features/middlewares";
export const store = configureStore({
  reducer: {
    admin: adminReducer,
    allAdmins: allAdminReducer,
    sidebar: sidebarReducer,
    storeDetails: storeDetailsReducer,
    allStores: allStoresReducer,
    trendingStores: trendingStoreReducer,
    commonStores: allCommonStoreReducer,
    allOffers: allOfferReducer,
    offerDetails: offerDetailsReducer,
    activities: activityReducer,
    allOffers: allOfferReducer,
    affiliations: allAffiliationReducer,
    allCommonAffiliations: allCommonAffiliationsReducer,
    affiliationDetails: affiliationDetailsReducer,
    allMainLinks: allMainLinkReducer,
    allSubLinks: allSubLinkReducer,
    subLinkDetails: subLinkDetailsReducer,
    mainLinkDetails: mainLinkDetailsReducer,
    allUsers: allUserReducer,
    userDetails: userDetailsReducer,
    allChannels: allChannelReducer,
    channelDetails: channelDetailsReducer,
    allAccounts: allAccountReducer,
    accountDetails: accountDetailsReducer,
    allBonus: allBonusReducer,
    categories: allCategoriesReducer,
    commonCategories: allCommonCategoriesReducer,
    subCategories: allSubCategoriesReducer,
    commonSubCategories: allCommonSubCategoriesReducer,
    subCategoryDetails: subCategoryDetailsReducer,
    allMiddlewares: middlewareReducer,
  },
});
